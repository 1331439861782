import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Panel, PanelItem } from '../../src';
import ProductCard from './helpers/ProductCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "panel"
    }}>{`Panel`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Panel from '@mfrm/mfcl/Panel'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Panels group related content and may contain actions about that content`}</p>
    <h2 {...{
      "id": "panel-properties"
    }}>{`Panel Properties`}</h2>
    <Props of={Panel} mdxType="Props" />
    <h2 {...{
      "id": "panel-item-properties"
    }}>{`Panel Item Properties`}</h2>
    <Props of={PanelItem} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage-and-variations"
    }}>{`Basic Usage and variations`}</h2>
    <p>{`Panels come in two variations and house panel items within them`}</p>
    <Playground __position={2} __code={'<Panel>\n  <PanelItem>Content</PanelItem>\n</Panel>\n<br />\n<Panel rounded>\n  <PanelItem>Content</PanelItem>\n</Panel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Panel,
      PanelItem,
      ProductCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Panel mdxType="Panel">
    <PanelItem mdxType="PanelItem">Content</PanelItem>
  </Panel>
  <br />
  <Panel rounded mdxType="Panel">
    <PanelItem mdxType="PanelItem">Content</PanelItem>
  </Panel>
    </Playground>
    <h2 {...{
      "id": "headers-and-footers"
    }}>{`Headers and Footers`}</h2>
    <p>{`Special panel item types can be designated by the type property`}</p>
    <Playground __position={3} __code={'<Panel>\n  <PanelItem type=\"header\">Header</PanelItem>\n  <PanelItem type=\"body\">Body</PanelItem>\n  <PanelItem type=\"link\" href=\"/components-flag\">\n    Link\n  </PanelItem>\n  <PanelItem type=\"footer\">Footer</PanelItem>\n</Panel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Panel,
      PanelItem,
      ProductCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Panel mdxType="Panel">
    <PanelItem type="header" mdxType="PanelItem">Header</PanelItem>
    <PanelItem type="body" mdxType="PanelItem">Body</PanelItem>
    <PanelItem type="link" href="/components-flag" mdxType="PanelItem">
      Link
    </PanelItem>
    <PanelItem type="footer" mdxType="PanelItem">Footer</PanelItem>
  </Panel>
    </Playground>
    <h2 {...{
      "id": "custom-styling"
    }}>{`Custom Styling`}</h2>
    <p>{`Apply custom styling to both panel and panel item components`}</p>
    <Playground __position={4} __code={'<Panel rounded style={{ color: \'red\' }}>\n  <PanelItem type=\"header\" style={{ textAlign: \'center\' }}>\n    Centered Header\n  </PanelItem>\n  <PanelItem>Some information</PanelItem>\n  <PanelItem\n    type=\"footer\"\n    style={{ backgroundColor: \'grey\', color: \'white\' }}\n  >\n    footer\n  </PanelItem>\n</Panel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Panel,
      PanelItem,
      ProductCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Panel rounded style={{
        color: 'red'
      }} mdxType="Panel">
    <PanelItem type="header" style={{
          textAlign: 'center'
        }} mdxType="PanelItem">
      Centered Header
    </PanelItem>
    <PanelItem mdxType="PanelItem">Some information</PanelItem>
    <PanelItem type="footer" style={{
          backgroundColor: 'grey',
          color: 'white'
        }} mdxType="PanelItem">
      footer
    </PanelItem>
  </Panel>
    </Playground>
    <h2 {...{
      "id": "nested-components"
    }}>{`Nested Components`}</h2>
    <p>{`Nest any other component within the panel`}</p>
    <Playground __position={5} __code={'<Panel>\n  <PanelItem type=\"header\">Header</PanelItem>\n  <ProductCard\n    brand=\"Serta\"\n    productImg=\"https://i1.adis.ws/i/hmk/133558\"\n    title=\'Serta Response Essentials Maplewood 8.5\" Firm Mattress\'\n    size=\"Wumbo\"\n    discountPrice=\"2300\"\n    price=\"3500\"\n    matchPercentage=\"98\"\n    reviews=\"215\"\n    rating=\"4\"\n  />\n  <PanelItem type=\"footer\">Footer</PanelItem>\n</Panel>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Panel,
      PanelItem,
      ProductCard,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Panel mdxType="Panel">
    <PanelItem type="header" mdxType="PanelItem">Header</PanelItem>
    <ProductCard brand="Serta" productImg="https://i1.adis.ws/i/hmk/133558" title='Serta Response Essentials Maplewood 8.5" Firm Mattress' size="Wumbo" discountPrice="2300" price="3500" matchPercentage="98" reviews="215" rating="4" mdxType="ProductCard" />
    <PanelItem type="footer" mdxType="PanelItem">Footer</PanelItem>
  </Panel>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      